
import { Watch, Component, Vue, PropSync, Ref, Prop } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form"

import { storeList } from "@/api/store";
import { bindProductStore, getProductStore } from "@/api/product";

import { debounce } from "lodash";

@Component
export default class ProductListBindStore extends Vue {
    @PropSync("visible") dialogVisible !: boolean;
    @Ref("formRef") formRef !: ElForm;
    @Prop() bindStoreProductInfo !: any;

    form = {
        storeName: "",
        storeId: "",
    }

    storeSelectLoading = false;

    allStoreList = [];

    rules = {
        storeId: [{required: true, message: "请选择关联门店", trigger: "blur"}]
    };

    getStoreRemote = debounce(function (str) {
        this.storeSelectLoading = true;
        getProductStore({ productId: this.bindStoreProductInfo?.id, keyWord: str }).then((res) => {
          this.storeSelectLoading = false;
          this.allStoreList = res.data;
        }).catch((err) => {});
    }, 800)
  
    getStoreList(str) {
      if (typeof str == "object") {
        str = "";
      }
      this.getStoreRemote(str)
    }
    

    dialogClose() {
        this.dialogVisible = false;
        this.formRef?.resetFields();
        this.allStoreList = [];
    };

    cancel() {
        this.dialogClose();
    }

    save() {
        this.formRef.validate().then(() => {
            const params = { productId: this.bindStoreProductInfo?.id, storeId: this.form.storeId }
            bindProductStore(params).then(() => {
                this.$message.success("操作成功");
                this.$emit("syncList")
                this.dialogClose();
            })
        })
    }

    @Watch("dialogVisible")
    dialogVisibleChange(val) {
        if (!val) return;
        const { storeId, storeName } = this.bindStoreProductInfo;
        if (storeId && storeName){
            this.form.storeId = storeId;
            this.allStoreList = [{ storeId, storeName }]
        }
    }
}
