import { service } from '@/ajax/request'


export const productExpendList = (params: any) => (
  service({
    url: '/pms/pmsProductExpend/list',
    method: 'POST',
    params,
    type: 'form'
  })
)


// codeNumber	编码数量	query	false	
// integer(int32)
// createTime		query	false	
// string(date-time)
// deviceTypeId	设备类型id	query	false	
// integer(int32)
// deviceTypeName	设备类型名称	query	false	
// string
// id	产品id	query	false	
// integer(int32)
// productName	产品名称	query	false	
// string
// updateTime
export const saveProduct = (params: any) => (
  service({
    url: '/pms/pmsProduct/saveProduct',
    method: 'POST',
    params,
    type: 'form'
  })
)

export const productList = (params: any) => (
  service({
    url: '/pms/pmsProduct/list',
    method: 'POST',
    params,
  })
)


export const download = (params: any) => (
  service({
    url: '/pms/pmsProductCode/download',
    method: 'GET',
    params,
    type: 'download',
    responseType: 'blob'
  })
)

export const downloadCode = (params: any) => (
  service({
    url: '/pms/pmsProductCode/downloadCode',
    method: 'GET',
    params,
    type: 'download',
    responseType: 'blob'
  })
)

export const bindProductStore = (params: any) => (
  service({
    url: '/pms/pmsProductStore/saveOrUpdateStore',
    method: 'POST',
    params,
  })
)

export const getProductStore = (params: any) => (
  service({
    url: '/pms/pmsProductStore/getProductStore',
    method: 'POST',
    params,
  })
)

